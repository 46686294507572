import React from "react";
import styled from "styled-components";

import { BlocksContent, Button, Image, Cta } from "@global";
import { Container, RadialGradient, Separator } from "@util/standard";
import { assets, colors } from "@util/constants";
import { SanityHero, Maybe } from "@graphql-types";
import { FullHeightContainer } from "@shared/shared.styles";

const HeroContentContainer = styled(Container)<{ width: string | undefined }>`
  max-width: 640px;
  position: absolute;
  flex-direction: column;
  bottom: ${props => (props.width ? "2%" : "10%")};
  left: 0;
  right: 0;
  width: 80%;
  margin: ${props => (props.width ? "auto auto 0 10%" : "auto auto 7% 10%")};
  z-index: 2;

  p {
    color: ${colors.white};
  }
`;

interface Props {
  heroData: Maybe<SanityHero> | undefined;
  height?: string;
  width?: string;
}

const Hero = ({ heroData, height, width }: Props) => {
  if (heroData == null) {
    return null;
  }

  const { imageWithMeta, blockContent, links } = heroData;
  const imageData = imageWithMeta?.asset?.gatsbyImageData;

  return (
    <FullHeightContainer height={height} width={width}>
      <Image
        isBackground
        staticImage={assets.placeholder}
        imageData={imageData}
        altText={imageWithMeta?.altText ?? "heroImage"}
        objectFit="cover"
        vimeoUrl={imageWithMeta?.vimeoUrl}
      />

      {imageData && <RadialGradient zIndex={2} />}
      <HeroContentContainer width={width}>
        {imageData && <Separator marginBottom={1} />}
        <BlocksContent data={blockContent} />
        {links && (
          <Container margin="30px 0 15px 0">
            {links.map(link => {
              if (link == null) {
                return link;
              }

              return <Cta key={link._key} data={link} height={height} />;
            })}
          </Container>
        )}
      </HeroContentContainer>
    </FullHeightContainer>
  );
};

export default Hero;
